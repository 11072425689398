import Image from "next/image";
import Link from "next/link";

import appstore from "@/assets/img/appstore_button.png";
import bulletOrange from "@/assets/img/home/bullet_orange.svg";
import graphismBottom from "@/assets/img/home/graphism_bottom_mobile.svg";
import graphism from "@/assets/img/home/graphism_mobile.svg";
import bulletBlue from "@/assets/img/how-it-works/bullet.svg";
import playstore from "@/assets/img/playstore_button.png";
import { NextMuiLink } from "@/components/Link/NextMuiLink";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import { Box, Stack, Typography } from "@mui/material";

import { DownloadAppProps } from "../DownloadApp/types";
import { styles } from "./styles";

export const DownloadAppMobile: React.FC<DownloadAppProps> = ({ description, image, qrCodeText, qrcodeImage }) => {
  return (
    <Box css={styles.box}>
      <Box position="relative">
        <Image src={graphism} alt="" css={styles.graphism} />
        <Box css={styles.topline} />
      </Box>
      <Stack bgcolor="#FFD100" textAlign="center" px={8} css={styles.stack_typography}>
        <Image src={bulletBlue} alt="" css={styles.bulletBlue} />

        <Typography component="div" fontSize={32} lineHeight="40px" css={styles.typography}>
          {documentToReactComponents(description as Document)}
        </Typography>

        <NextMuiLink href="/regulamento" color="#000000DE">
          *Consulte o regulamento
        </NextMuiLink>

        <Image src={bulletOrange} alt="" css={styles.bulletOrange} />
        <Box css={styles.middleline} />
      </Stack>

      <Stack bgcolor="#FF8B00" height={400} justifyContent="start" position="relative">
        <Stack spacing="20px" mt={4} px={2} py="20px" css={styles.card_qrcode}>
          <Typography component="div" fontWeight={500} fontSize={14} textAlign="center">
            {documentToReactComponents(qrCodeText as Document)}
          </Typography>

          <Image src={qrcodeImage} alt="QR Code" width={141} height={147} />
        </Stack>

        <Box css={styles.bottomline} bottom={1} />
        <Image src={graphismBottom} alt="" css={styles.graphism_bottom} />
      </Stack>

      <Stack
        position="relative"
        bgcolor="#FFD100"
        flexDirection="column"
        gap={1}
        height="237px"
        alignItems="end"
        justifyContent="start"
        pt="28px"
        pr={{ xs: 2, sm: 12 }}
      >
        <Image src={image} alt="App KMV" height={306} width={251} css={styles.image_cellphone} />

        <Link href="https://play.google.com/store/apps/details?id=com.gigigo.ipirangaconectcar&hl=en" target="_blank">
          <Image src={playstore} alt="Download do app no Google Play" width={115} style={{ cursor: "pointer" }} />
        </Link>
        <Link href="https://apps.apple.com/us/app/kmv-abastece-ai-cashback/id1052059383" target="_blank">
          <Image src={appstore} alt="Download do app na App Store" width={115} style={{ cursor: "pointer" }} />
        </Link>
      </Stack>
    </Box>
  );
};
