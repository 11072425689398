import { css } from "@emotion/react";

export const styles = {
  box: css`
    margin-top: 120px;
    height: 400px;
    display: flex;
    background-color: #ffd100;
    position: relative;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
  `,
  stack_typography: css`
    display: flex;
    width: 460px;
    align-self: center;
    position: relative;

    @media (min-width: 900px) and (max-width: 1200px) {
      width: 270px;
      z-index: 2;
    }
  `,
  stack_images: css`
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
  `,
  image_cellphone: css`
    position: absolute;
    top: -48px;
    left: 192px;
    z-index: 1;

    @media (min-width: 1740px) {
      left: 470px;
    }

    @media (max-width: 1366px) {
      left: 192px;
    }

    @media (max-width: 1312px) {
      top: -41px;
      left: 87px;
      width: 340px;
      height: auto;
    }

    @media (min-width: 900px) and (max-width: 1200px) {
      top: -58px;
      left: 46px;
    }
  `,
  card_qrcode: css`
    height: 258px;
    width: 203px;
    background-color: #ffffff;
    border-radius: 16px;
    align-self: center;
    align-items: center;
  `,

  graphism: css`
    overflow: hidden;
    z-index: 1;

    @media (min-width: 900px) and (max-width: 1280px) {
      position: relative;
      bottom: 0px;
      left: -9px;
    }
  `,

  topline: css`
    position: absolute;
    border-top: 3px solid #0045b5;
    top: 0px;
    left: 0;
    width: 100%;
  `,
  bottomline: css`
    position: absolute;
    border-bottom: 3px solid #0045b5;
    bottom: 0px;
    width: 100%;
  `,
  bulletBlue: css`
    position: absolute;
    top: -14px;
    right: 50px;
  `,
  bulletOrange: css`
    position: absolute;
    bottom: -15px;
    left: 264px;
  `,

  span: css`
    font-size: 36px;
    font-weight: 700;

    @media (min-width: 900px) and (max-width: 1200px) {
      font-size: 28px;
    }
  `
};
