import Image from "next/image";
import Link from "next/link";

import appstore from "@/assets/img/appstore_button.png";
import bulletOrange from "@/assets/img/home/bullet_orange.svg";
import graphism from "@/assets/img/home/graphism_diagonal.svg";
import bulletBlue from "@/assets/img/how-it-works/bullet.svg";
import playstore from "@/assets/img/playstore_button.png";
import { NextMuiLink } from "@/components/Link/NextMuiLink";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import { Box, Stack, Typography } from "@mui/material";

import { styles } from "./styles";
import { DownloadAppProps } from "./types";

export const DownloadApp: React.FC<DownloadAppProps> = ({ description, image, qrCodeText, qrcodeImage }) => {
  return (
    <Box css={styles.box}>
      <Stack bgcolor="#FFD100" pl={{ xs: 2, sm: 2, md: 4, lg: 10 }} height="100%" justifyContent="center" css={styles.stack_typography}>
        <Box css={styles.topline} />
        <Image src={bulletBlue} alt="" css={styles.bulletBlue} />

        <Typography component="div" fontSize={{ xs: 32, sm: 32, md: 24, lg: 32, xl: 32 }} lineHeight="40px">
          {documentToReactComponents(description as Document)}
        </Typography>

        <NextMuiLink href="/regulamento" color="#000000DE">
          *Consulte o regulamento
        </NextMuiLink>
      </Stack>

      <Box flex={1}>
        <Stack bgcolor="#FF8B00" pr={{ xs: 2, sm: 2, md: 4, lg: 10 }} css={styles.stack_images}>
          <Image src={graphism} alt="" css={styles.graphism} />
          <Image src={image} alt="App KMV" height={439} width={360} css={styles.image_cellphone} />

          <Stack spacing="20px" px={2} py="20px" css={styles.card_qrcode}>
            <Typography component="div" fontWeight={500} fontSize={14} textAlign="center">
              {documentToReactComponents(qrCodeText as Document)}
            </Typography>

            <Image src={qrcodeImage} alt="QR Code" width={141} height={147} />
          </Stack>

          <Box css={styles.bottomline} />
          <Image src={bulletOrange} alt="" css={styles.bulletOrange} />
        </Stack>

        <Stack flexDirection="row" gap={2} height="71px" alignItems="center" justifyContent="end" pr={{ xs: 2, sm: 2, md: 4, lg: 10 }}>
          <Link href="https://play.google.com/store/apps/details?id=com.gigigo.ipirangaconectcar&hl=en" target="_blank">
            <Image src={playstore} alt="Download do app no Google Play" width={115} style={{ cursor: "pointer" }} />
          </Link>
          <Link href="https://apps.apple.com/us/app/kmv-abastece-ai-cashback/id1052059383" target="_blank">
            <Image src={appstore} alt="Download do app na App Store" width={115} style={{ cursor: "pointer" }} />
          </Link>
        </Stack>
      </Box>
    </Box>
  );
};
