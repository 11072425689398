import { css } from "@emotion/react";

export const styles = {
  box: css`
    display: flex;
    margin-top: 20px;
    align-content: center;
    background-color: #ffd100;
    flex-direction: column;

    position: relative;
  `,
  stack_typography: css`
    display: flex;
    width: 460px;
    height: 340px;
    align-self: center;
    justify-content: center;
    position: relative;

    border-top: 3px solid #0045b5;
  `,
  stack_images: css`
    flex: 1;
    flex-direction: row;
    justify-content: center;
    position: relative;
  `,
  image_cellphone: css`
    position: absolute;
    top: -75px;
    left: 28px;
    z-index: 1;

    @media (min-width: 600px) and (max-width: 900px) {
      left: 158px;
    }
    @media (max-width: 389px) {
      width: 200px;
      height: auto;
      top: -20px;
      left: 8px;
    }
  `,
  card_qrcode: css`
    height: 258px;
    width: 203px;
    background-color: #ffffff;
    border-radius: 16px;
    align-self: center;
    align-items: center;
  `,

  graphism: css`
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  `,
  graphism_bottom: css`
    position: absolute;
    left: 0;
    bottom: 0;
  `,

  topline: css`
    position: absolute;
    border-top: 3px solid #0045b5;
    top: 0px;
    width: 100%;
  `,
  middleline: css`
    position: absolute;
    border-bottom: 3px solid #0045b5;
    bottom: 0px;
    left: -25vw;
    width: 910px;
  `,
  bottomline: css`
    position: absolute;
    border-bottom: 3px solid #0045b5;
    bottom: 1px;
    width: 100%;
  `,
  bulletBlue: css`
    position: absolute;
    top: -14px;
    right: 156px;
  `,
  bulletOrange: css`
    position: absolute;
    bottom: -14px;
    left: 82px;
    z-index: 1;
  `,

  typography: css`
    @media (max-width: 389px) {
      font-size: 24px;
    }
  `
};
